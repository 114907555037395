export const requestStatus = {
  EN_PROCESO: 0,
  COMPLETADA: 1,
  APROBADA: 2,
  RECHAZADA: 3,
  CANCELADA: 4
};
export const statusFishermen = {
  EN_PROCESO: 1,
  COMPLETADA: 2,
  APROBADA: 3,
  RECHAZADA: 4,
  CANCELADA: 5
};
export const requestStatusDesc = {
  COMPLETED: 'COMPLETADA',
  PROCESS: 'EN PROCESO',
  ACEPTED: 'APROBADA',
  REFUSED: 'RECHADAZA',
  CANCELED: 'CANCELADA',
  CORRECTED: 'CORREGIDO'
};

Object.freeze(requestStatusDesc);
Object.freeze(requestStatus);
Object.freeze(statusFishermen);