<template>
  <div>
    <ToggleNavigation :items="items" :type="type"></ToggleNavigation>
    <ComponentModalInfoGeneral
      v-on:handlePDF="generatePDF"
      v-on:handleClose="
        dialogSee = false;
        dataParent = [];
      "
      :modal="dialogSee"
      :download="download"
      :dataModal="dataParent"
      :title="title"
      v-if="dialogSee"
    ></ComponentModalInfoGeneral>

    <ComponentModalActivityLog
      v-on:handleClose="
      dialogSeeActivityLog = false; "
      :modal="dialogSeeActivityLog"
      :type="type"
      process="procesos_"
      :consecutive="consecutive"
      v-if="dialogSeeActivityLog"
    ></ComponentModalActivityLog>

    <v-card class="mx-auto">
      <v-card-title class="indigo darken-4 white--text">
        <v-icon dark class="mx-4" @click="$router.push('/companies-' + type)"
          >arrow_back
        </v-icon>
        Listado de Procedimientos
      </v-card-title>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :headers="headers"
        :items="filtrado.data"
        :search="searchRequest"
        class="elevation-1"
        :server-items-length="filtrado.total"
        :items-per-page="perPage"
        v-on:update:options="onPaginate"
      >
        <template v-slot:top>
            
          <v-toolbar flat color="white">
            <v-menu v-model="showMenuStatus">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ nameFilterStatus}}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="setFilterStatus(null, $event)"
                >
                  <v-list-item-title>Estados</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(status, statusDesc) in allStatus"
                :key="status"
                style="cursor: pointer"
              >
                <v-list-item-content
                  @click="setFilterStatus(status, $event)"
                >
                  <v-list-item-title> {{ statusDesc | capitalize | pascal  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
           <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ nameFilterRequestType}}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="setFilterRequestType(null, $event)"
                >
                  <v-list-item-title>Tipo de solicitud</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(item, index) in itemsRequestTypes"
                :key="index"
                style="cursor: pointer"
              >
                <v-list-item-content
                  @click="setFilterRequestType(item, $event)"
                >
                  <v-list-item-title> {{ item.nombre }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
           <v-badge class="ml-2" :content="filterApplied" :value="filterApplied" color="green" overlap>
         <v-icon large> mdi-filter </v-icon>
        </v-badge>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchRequest"
              append-icon="search"
              label="Busqueda"
              single-line
              hide-details
              v-debounce:200ms="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip dark :color="getColorByStatus(item.status)">{{
            getStatus(item.status)
          }}</v-chip>
        </template>
        <template v-slot:item.estadoTramite="{ item }">
          <template v-if="item.estado == 6">
            <v-chip color="white"><span style="color:black">Corregido</span></v-chip>
          </template>
          <template v-else>
            <template v-if="item.estadoTramite != 'Tramitado'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-if="item.estadoTramite == 'Vencido'">
                      <v-chip color="white"><span style="color:red"><b>{{
                        item.estadoTramite
                      }}</b></span></v-chip>
                    </template>
                    <template v-if="Number.isInteger(item.estadoTramite)">
                      <v-chip color="white"><span style="color:orange"><b>Quedan {{
                         item.estadoTramite
                      }} días</b></span></v-chip>
                    </template>
                  </span>
                </template>
                <span>Vence en: {{item.expiresIn}}</span>
              </v-tooltip>
            </template>
            <template v-else>
              <v-chip color="white"><span style="color:black">{{
                item.estadoTramite
              }}</span></v-chip>
            </template>
          </template>
        </template>
        <template v-slot:item.boats="{ item }">
          {{ getBoatsDesc(item) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
            
              <template v-if="(item.estado <= 1) || item.estado == 6" >
                <v-list-item
                  v-if=" type == 'art'
                  ? $can('gestionarProcesosArtesanales')
                  : $can('gestionarProcesosIndustriales')
                  "
                  @click="Peticion('mod', item, 'CT')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>edit</v-icon>
                    Tramitar
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-if="(item.estado == 2) && (item.ult_request_by_company == null || item.ult_request_by_company.consecutive == item.consecutive )" >
                <v-list-item
                  v-if=" type == 'art'
                  ? $can('gestionarProcesosArtesanales')
                  : $can('gestionarProcesosIndustriales')
                  "
                  @click="Peticion('cor', item, 'CT')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>edit</v-icon>
                    Corregir
                  </v-list-item-title>
                </v-list-item>
              </template>

              <template
              v-if="type == 'art'
                ? $can('cancelarProcedimientosArtesanales')
                : $can('cancelarProcedimientosIndustriales')
              "
              >
                <v-list-item
                  @click="cancelRequest(item)"
                  v-if="item.estado <= 1 || item.estado == 6"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>cancel</v-icon>Cancelar
                  </v-list-item-title>
                </v-list-item>
              </template>
              
              <template v-if="item.estado == 1 || item.estado == 6">
                <v-list-item
                  v-if="type == 'art'
                  ? $can('aprobarProcedimientosArtesanales')
                  : $can('aprobarProcedimientosIndustriales')
                "
                  @click="Peticion('resolv', item, 'CT')"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2" small>check</v-icon>
                    <template v-if="item.estado == 1">
                      Resolver
                    </template>
                    <template v-else>
                      Finalizar Corrección
                    </template>
                  </v-list-item-title>
                </v-list-item>
              </template>

              <template v-if="(item.estado > 0)">
                <v-list-item v-if="type == 'art' 
                ? $can('verDetalleDeProcedimientoArtesanales')
                : $can('verDetalleDeProcedimientoIndustriales')" 
                @click="seeItem(item)">
                <v-list-item-title>
                  <v-icon class="mr-2" small>remove_red_eye</v-icon>
                  Detalle
                </v-list-item-title>
              </v-list-item>
              </template>
                <v-list-item  @click="seeActivityLog(item.consecutive)">
                <v-list-item-title>
                  <v-icon class="mr-2" small>fas fa-history</v-icon>
                 Historial de actividades
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ComponentModalInfoGeneral from "../../components/ComponentModalInfoGeneral";
import ComponentModalActivityLog from "../../components/ComponentModalActivityLog";
import { getConceptTechnicalArt, firmaUrl } from "../../config.js";
import ToggleNavigation from "../../components/ToggleNavigation";
import { navegacion } from "../../services/exportNavigation";
import { consultar } from "../../services/variablesconcepto";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { convert } from "../../services/numToWord";
import { requestTypes } from "../../enums/requestTypes";
import companyTypes from "../../enums/companyTypes";
import { requestStatusDesc, requestStatus } from "../../enums/requestState";
import {
  getRequestTypes,
} from "../../services/DataSourceService";
import escudogobernacion from "../../assets/media/rpg/escudogobernacion.png";
import watermark from "../../assets/media/rpg/watermark.png";
import {companyService} from "../../services/CompanyService";
import swal from "sweetalert";
import { requestables } from "../../enums/requestables";
let concepto = {};

export default {
  components: {
    ComponentModalInfoGeneral,
    ComponentModalActivityLog,
    ToggleNavigation
  },
  props: ["type"],
  data: () => ({
    items: [],
    convert: convert,
    searchRequest: "",
    dataParent: [],
    consecutive: '',
    title: "",
    firma_secre: "",
    firma_fun: "",
    firma_rep: "",
    dialogSee: false,
    dialogSeeActivityLog: false,
    download: false,
    showMenuStatus: false,
    nameFilterStatus: null,
    nameFilterRequestType: null,
    filterStatus: null,
    filterRequestType: null,
    currentItemsPerPage: 10,
    allStatus: requestStatus,
    headers: [
      {
        text: "Fecha del Procedimiento",
        value: "created_at",
        sortable: true,
      },
      {
        text: "Fecha de actualización",
        value: "updated_at",
        sortable: true,
      },
      {
        text: "Permisionario",
        align: "left",
        value: "owner_permit",
      },
      { text: "Consecutivo", value: "consecutive", sortable: false },
      { text: "Embarcaciones", value: "boats", sortable: false },
      { text: "Tipo de Procedimiento", value: "request_type.nombre" },
      { text: "Estado", value: "status", sortable: false },
      { text: "Vigencia", value: "estadoTramite", sortable: false },
      { text: "Acciones", value: "action", sortable: false },
    ],
    dataItem: {},
    company: {},
    permiso: {
      expedido_por: {},
      titular_autorizacion: {},
      zona_autorizada: {},
      puerto_desembarque_artesanal: {},
      tipo_pesqueria: {},
      arte_pesca: {},
      especie_autorizada: {},
    },
    barco: {},
    barcos: {},
    lugar: {},
    itemsRequestTypes: [],
    tipo_autorizacion: {},
    tipo_solicitud: {},
    certificado_expedido_por: {},
    artes_autorizadas: [],
    type_of_company: "",
    defaultItem: {},
    funcionario: {},
    escudo: null,
    watermark: null,
    filtrado: [],
    fisherman : {},
    concepto : {},
    currentPage: 1
  }),

  computed: {
    ...mapGetters(["allRequests", "currentFilters"]),
       filterApplied() {
      return (
        Number(Boolean(this.filterRequestType)) +
        Number(Boolean(this.filterStatus))
      );
    },
    perPage: function (){
      if (!this.filtrado.length){
        return this.currentFilters.currentItemsPerPage;
      }
      return Number(this.filtrado.per_page)
    },
    requestables(){
      return requestables;
    }
  },
  async created() {
    this.items = navegacion.items_navegation;
    this.escudo = await this.getBase64(escudogobernacion);
    this.watermark = await this.getBase64(watermark);
  },
  async beforeMount(){
    this.itemsRequestTypes = (await getRequestTypes())?.data || [];
  },

  methods: {
    getStatus(status) {
      return {
        completed: requestStatusDesc.COMPLETED,
        process: requestStatusDesc.PROCESS,
        acepted: requestStatusDesc.ACEPTED,
        refused: requestStatusDesc.REFUSED,
        canceled: requestStatusDesc.CANCELED,
        corrected: requestStatusDesc.COMPLETED // requestStatusDesc.CORRECTED
      }[status];
    },
    getColorByStatus(status) {
      return {
        completed: "purple",
        process: "orange",
        acepted: "green",
        refused: "red",
        corrected: "purple",
      }[status];
    },
    getBoatsDesc({ boats, boat_unique_on_request: boat }) {
      let boatDesc = "";
      if (boat) {
        boatDesc += boat.boat_desc;
      } else {
        boats.forEach((boat, i) => {
          boatDesc += boat.boat_desc;
          if (i < boats.length - 1) {
            boatDesc += ", ";
          }
        });
      }

      return boatDesc;
    },
    setFilterStatus(status, event) {
      this.$store.commit("SET_CURRENT_FILTERS", {
        filterStatus: status?.toString(),
        nameFilterStatus: event.target.innerText
      });
      this.nameFilterStatus = this.currentFilters.nameFilterStatus;
      this.filterStatus = this.currentFilters.filterStatus;
      const companyType = this.getCompanyType();
      this.$store.commit('SET_CURRENT_PAGE', 1);
      this.filterRequest(companyType);
    },
    setFilterRequestType(item, event){
      this.$store.commit("SET_CURRENT_FILTERS", {
        filterRequestType: item?.codigo,
        nameFilterRequestType: event.target.innerText
      });
      this.nameFilterRequestType = this.currentFilters.nameFilterRequestType;
      this.filterRequestType = this.currentFilters.filterRequestType;
      const companyType = this.getCompanyType();
      this.$store.commit('SET_CURRENT_PAGE', 1);
      this.filterRequest(companyType);
    },
    getCompanyType() {
      return {
        art: companyTypes.ARTESANAL,
        ind: companyTypes.INDUSTRIAL,
      }[this.type];
    },
    search() {
      this.$store.commit("LOADER", undefined);
      //this.$store.commit("START_LOADER");
      const companyType = this.getCompanyType();
      this.filterRequest(companyType);
    },
    async filterRequest(companyType) {
      this.filtrado = await this.getRequests(companyType, {
        page: 1,
        itemsPerPage: this.currentFilters.currentItemsPerPage,
        ...this.getCurrentFilter(),
      });
    },
    getCurrentFilter() {
      this.filterRequestType = this.currentFilters?.filterRequestType;
      this.filterStatus = this.currentFilters?.filterStatus;
      this.nameFilterStatus = this.currentFilters?.nameFilterStatus || 'Estados';
      this.nameFilterRequestType = this.currentFilters?.nameFilterRequestType || 'Tipos de solicitud';
      return {
        ...(this.filterRequestType && { requestType: this.filterRequestType }),
        ...(this.filterStatus && { status: this.filterStatus }),
        ...(this.searchRequest && { search: this.searchRequest }),
      };
    },
    onPaginate: async function (pagination) {
      const { sortBy, sortDesc, page } = pagination;
      this.currentPage = page;
      this.filtrado = await this.getRequests(this.getCompanyType(), {
            page: this.currentPage,
            itemsPerPage: this.currentFilters.currentItemsPerPage,
            ...this.getCurrentFilter(),
            ...(sortBy[0] && {
              [`${sortBy[0]}`]: sortDesc[0],
            }),
        });
      
      
    },
    async getRequests(type, options) {
      await this.$store.dispatch("getRequests", { type, options });
      return this.allRequests;
    },
    async cancelRequest(requestItem) {
      const value = await swal("Seguro desea continuar", {
        buttons: {
          cancel: "Cancelar",
          Si: true,
        },
      });
      if (value === "Si") {
        await companyService.cancelRequest(requestItem.consecutive);
        this.$toasted.success("Solicitud cancelada con exito", {
          position: "top-right",
          duration: 5000,
        });
        const companyType = this.getCompanyType();
        this.filterRequest(companyType);
      } else {
        return false;
      }
    },
    valor_tasa() {
      return this.convert(this.editedItem.valor_tasa_anual);
    },
    Peticion(method, item) {
      window.location.href = `/company-request-${this.type}/${method}/${item.company_id}/${item.consecutive}`;
    },
    seeItem: async function (item) {
      this.$store.commit('LOADER', true);
      //this.$store.commit("START_LOADER");
      concepto = await consultar.fetch();
      this.download = false;
      if (item.request_type.codigo == requestTypes.PATENTE_PESCA) {
        this.title = "CP";
      } else if (item.request_type.codigo == "CERO") {
        this.title = "IT";
      } else {
        this.title = "CT";
      }

      var path = `${getConceptTechnicalArt}?nit=${item.company_id}&q=${item.consecutive}`;

      this.axios.get(path).then(async (response) => {
        if (response.status === 200) {
          var url = firmaUrl + response.data.data.firma_secretario;
          var url_fun = firmaUrl + response.data.funcionario.firma;
          
          var url_rep = firmaUrl + response.data.data.firma_representante;
          if (this.title != "CP") {
            if (this.title != "IT") {
              this.firma_secre = await this.getBase64(url);
              this.firma_fun = await this.getBase64(url_fun);
              this.download = true;
            } else {
              this.firma_fun = await this.getBase64(url_fun);
              this.firma_rep = await this.getBase64(url_rep);
              Promise.all([this.firma_rep, this.firma_fun]).then(() => {
                this.download = true;
              });
            }
          } else {
              this.download = true;
          }


          this.dataItem = response.data.data;
          this.company = this.dataItem.company;
          this.first_permission = response.data.first_permission;

          if (!this.dataItem.permission) {
            if ((this.company?.permissions || []).length > 0)
              this.permiso =
                this.company.permissions[this.company.permissions.length - 1];
          }
          else {
            this.permiso = this.dataItem.permission;
          }

          this.dataParent = {...this.dataItem, boat: this.dataItem.boat_unique_on_request};
          this.editedItem = {...this.editedItem, ...this.dataParent};
          this.tipo_solicitud = this.dataParent.request_type;

          this.funcionario = response.data.funcionario;
          this.lugar = this.dataItem.lugar;
          this.tipo_autorizacion = this.dataItem.tipo_autorizacion;
          this.certificado_expedido_por = this.dataItem.company?.certificado_expedido_por;
  
          this.barcos = this.dataItem.boats;
          this.barco = this.dataParent.boat || this.dataItem.boats[0];

          if (this.dataItem.company?.type_of_company == "UNO") {
            this.type_of_company = "NATURAL";
          } else if (this.dataItem.company?.type_of_company == "DOS") {
            this.type_of_company = "JURIDICA";
          }
        }
        
      });
      this.dialogSee = true;
    },
    seeActivityLog: async function (consecutive) {
      this.consecutive = consecutive;
      this.dialogSeeActivityLog = true;
    },
    generatePDF() {
      if (this.title == "CT") {
        this.CTAgeneratePDF();
      }
      if (this.title == "CP") {
        this.CPAgeneratePDF();
      }
      if (this.title == "IT") {
        this.ITinspectionPDF();
      }
    },
    getBase64(url) {
      return this.axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then((response) =>
          Buffer.from(response.data, "binary").toString("base64")
        );
    },
    ITinspectionPDF() {
      let doc = new jsPDF();
      doc.addImage(this.escudo, "PNG", 15, 18, 20, 20);

      doc.line(15, 15, 195, 15); // horizontal line

      doc.setFontSize(12).text("Gobernación", 40, 23);
      doc.setFontSize(12).text("Departamento de San Andrés,", 40, 27);
      doc.setFontSize(12).text("Providencia y Santa Catalina", 40, 31);
      doc.setFontSize(12).text("Secretaría de Agricultura y Pesca", 40, 35);
      doc.line(15, 40, 195, 40); // horizontal line
      doc
        .setFontSize(14)
        .text(
          "INFORME DE INSPECCIÓN TÉCNICA A EMBARCACIONES PESQUERAS",
          15,
          50
        );
      doc.setFontSize(12).text("Fecha de", 120, 23);
      doc.setFontSize(12).text("Aprobación:", 120, 27);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(new Date().toISOString().substr(0, 10), 120, 31);
      doc.setFontSize(12).text("Código", 150, 23);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);
      doc
        .setFontSize(12)
        .text(
          `Funcionario Gobernación ${this.funcionario.firstname} ${
            this.funcionario.lastname
          } se precedió a la fecha ${
            this.editedItem.expedition_date
          } a realizar la inspección técnica a la embarcación ${
            this.barco.boat_name
          } - ${this.barco.registration_number} de bandera ${
            concepto.items_select_current_state_pavilion.find(
              (x) => x.abbr == this.barco.current_state_pavilion
            ) != undefined
              ? concepto.items_select_current_state_pavilion.find(
                  (x) => x.abbr == this.barco.current_state_pavilion
                ).state
              : "-"
          } de propiedad de ${this.barco.owner_name} afiliada a la empresa ${
            this.company.name
          }. La embarcación pretende ejercer la pesca mediante el (los) sistema (s) ${this.barco.fishing_arts
            .map((m) => m.pivot.description)
            .join(" ")}. Puerto base ${
            this.barco.fishing_port
              ? this.barco.type_boat == "IND"
                ? (
                    concepto.items_select_authorized_landing_port_ind.find(
                      (x) => x.codigo == this.barco.fishing_port
                    ) || {}
                  ).nombre
                : (
                    concepto.items_select_authorized_landing_port.find(
                      (x) => x.codigo == this.barco.fishing_port
                    ) || {}
                  ).nombre
              : this.barco.fishing_port_state
          }.`,
          15,
          60,
          {
            maxWidth: 180,
            align: "justify",
          }
        );
      doc.setFontSize(12).text("LOS DATOS ENCONTRADOS SON:", 15, 90);

      doc.autoTable({
        startY: 95,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Nombre",
            "Matrícula",
            "Lugar de Expedicion",
            "Expedicion de Matricula",
            "Vencimiento de la Matricula",
          ],
        ],
        body: [
          [
            this.barco.boat_name != null ? this.barco.boat_name : "-",
            this.barco.registration_number != null
              ? this.barco.registration_number
              : "-",
            this.barco.expedition_place != null
              ? this.barco.expedition_place
              : "-",
            this.barco.expedition_date != null
              ? this.barco.expedition_date
              : "-",

            this.barco.expiration_date != null
              ? this.barco.expiration_date
              : "-",
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          ["Estado actual del pabellón", "Puerto de Registro", "Tipo de barco"],
        ],
        body: [
          [
            concepto.items_select_current_state_pavilion.find(
              (x) => x.abbr == this.barco.current_state_pavilion
            ) != undefined
              ? concepto.items_select_current_state_pavilion.find(
                  (x) => x.abbr == this.barco.current_state_pavilion
                ).state
              : "-",

            concepto.items_select_registration_port.find(
              (x) => x.abbr == this.barco.registration_port
            ) != undefined
              ? concepto.items_select_registration_port.find(
                  (x) => x.abbr == this.barco.registration_port
                ).state
              : this.barco.registration_port_state != null
              ? this.barco.registration_port_state
              : this.barco.registration_port,
            this.barco.boat_type_state != null
              ? this.barco.boat_type_state
              : this.barco.boat_type != null
              ? this.barco.boat_type.nombre
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "ESLORA - Longitud total (LOA)(m)",
            "Calado/ Proyecto(m)",
            "Manga / extrema amplitud (m)",
            "Tonelaje de Registro Neto (TRN)",
            "Tonelaje de Registro Bruto (TRB)",
          ],
        ],
        body: [
          [
            this.barco.length_overall != null ? this.barco.length_overall : "-",
            this.barco.calado != null ? this.barco.calado : "-",
            this.barco.beam_extreme_breadth != null
              ? this.barco.beam_extreme_breadth
              : "-",
            this.barco.net_registered_tonnage != null
              ? this.barco.net_registered_tonnage
              : "-",
            this.barco.gross_registered_tonnage != null
              ? this.barco.gross_registered_tonnage
              : "-",
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Tipo de Propulsión",
            "Potencia del motor principal/s",
            "Unidad de Potencia",
            "Alto de la bodega de pescado (m)",
          ],
        ],
        body: [
          [
            this.barco.type_of_propulsion != null
              ? concepto.items_select_propulsion_type.find(
                  (x) => x.abbr == this.barco.type_of_propulsion
                ) != undefined
                ? concepto.items_select_propulsion_type.find(
                    (x) => x.abbr == this.barco.type_of_propulsion
                  ).state
                : this.barco.type_of_propulsion_state
              : "No Aplica",
            this.barco.main_engines_power != null
              ? this.barco.main_engines_power
              : "-",
            this.barco.power_unit != null
              ? concepto.items_select_power_unit.find(
                  (x) => x.abbr == this.barco.power_unit
                ).state
              : "-",
            this.barco.fish_hold_high != null ? this.barco.fish_hold_high : "-",
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Largo de la bodega de pescado (m)",
            "Ancho de la bodega de pescado (m)",
            "Capacidad de la bodega de pescado (m³)",
            "Tipo de conservación del pescado",
          ],
        ],
        body: [
          [
            this.barco.fish_hold_long != null ? this.barco.fish_hold_long : "-",
            this.barco.fish_hold_width != null
              ? this.barco.fish_hold_width
              : "-",
            this.barco.fish_hold_capacity != null
              ? this.barco.fish_hold_capacity
              : "-",
            this.barco.fish_hold_type_state != null
              ? this.barco.fish_hold_type_state
              : this.barco.fish_hold_type != null
              ? this.barco.fish_hold_type.nombre
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 5,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Artes de pesca"]],
        body: [
          [
            this.barco.fishing_arts.length > 0
              ? this.barco.fishing_arts.map((x) => x.pivot.description)
              : "No se registraron artes de pesca",
          ],
        ],
        theme: "grid",
      });
      if (this.barco.fishing_arts.find((x) => x.codigo == "UNO") != undefined) {
        doc
          .setFontSize(12)
          .text(
            `${this.barco.fishing_arts.find((x) => x.codigo == "UNO").nombre}`,
            15,
            doc.previousAutoTable.finalY + 5
          );

        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 7,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Denominación del arte de pesca tipo Sedal ó de anzuelo",
              "Tipo de anzuelo",
              "Tamaño del anzuelo",
              "Cantidad de anzuelos",
              "Longitud de la línea madre",
              "Material de la línea madre",
              "Material de las bajantes",
            ],
          ],
          body: [
            [
              concepto.items_select_type_line.find(
                (x) => x.abbr == this.barco.fishing_line
              ) != undefined
                ? concepto.items_select_type_line.find(
                    (x) => x.abbr == this.barco.fishing_line
                  ).state
                : this.barco.fishing_line_state != null
                ? this.barco.fishing_line_state
                : "-",
              this.barco.hook_type != null
                ? concepto.items_select_type_hook.find(
                    (x) => x.abbr == this.barco.hook_type
                  ).state
                : this.barco.hook_type_state != null
                ? this.barco.hook_type_state
                : "-",
              this.barco.hook_size != null ? this.barco.hook_size : "-",
              this.barco.hooks_number != null ? this.barco.hooks_number : "-",
              this.barco.mother_line_length != null
                ? this.barco.mother_line_length
                : "-",
              concepto.items_select_mother_line_material.find(
                (x) => x.abbr == this.barco.mother_line_material
              ) != undefined
                ? concepto.items_select_mother_line_material.find(
                    (x) => x.abbr == this.barco.mother_line_material
                  ).state
                : this.barco.mother_line_material_state != null
                ? this.barco.mother_line_material_state
                : "-",
              concepto.items_select_downspout_material.find(
                (x) => x.abbr == this.barco.downpipe_material
              ) != undefined
                ? concepto.items_select_downspout_material.find(
                    (x) => x.abbr == this.barco.downpipe_material
                  ).state
                : this.barco.downpipe_material_state != null
                ? this.barco.downpipe_material_state
                : "-",
            ],
          ],
          theme: "grid",
        });
      }
      if (this.barco.fishing_arts.find((x) => x.codigo == "DOS") != undefined) {
        doc
          .setFontSize(12)
          .text(
            `${this.barco.fishing_arts.find((x) => x.codigo == "DOS").nombre}`,
            15,
            doc.previousAutoTable.finalY + 5
          );

        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 7,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Denominación del arte de pesca tipo trampas o nasas",
              "Cantidad de trampas o nasas",
              "Material principal de la trampa o nasa",
            ],
          ],
          body: [
            [
              concepto.items_select_denomination_art_fishing.find(
                (x) => x.abbr == this.barco.art_type_traps
              ) != undefined
                ? concepto.items_select_denomination_art_fishing.find(
                    (x) => x.abbr == this.barco.art_type_traps
                  ).state
                : this.barco.art_type_traps_state != null
                ? this.barco.art_type_traps_state
                : "-",
              this.barco.type_traps_number != null
                ? this.barco.type_traps_number
                : "-",
              concepto.items_select_main_trap_material.find(
                (x) => x.abbr == this.barco.type_traps_material
              ) != undefined
                ? concepto.items_select_main_trap_material.find(
                    (x) => x.abbr == this.barco.type_traps_material
                  ).state
                : this.barco.type_traps_material_state != null
                ? this.barco.type_traps_material_state
                : "-",
            ],
          ],
          theme: "grid",
        });
      }

      if (
        this.barco.fishing_arts.find((x) => x.codigo == "TRES") != undefined
      ) {
        doc
          .setFontSize(12)
          .text(
            `${this.barco.fishing_arts.find((x) => x.codigo == "TRES").nombre}`,
            15,
            doc.previousAutoTable.finalY + 5
          );

        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 7,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Tipo de artefactos de herir o aferrar",
              "Cantidad de artefactos",
              "Material del artefacto",
            ],
          ],
          body: [
            [
              concepto.items_select_type_artifact.find(
                (x) => x.abbr == this.barco.type_wound_artifacts
              ) != undefined
                ? concepto.items_select_type_artifact.find(
                    (x) => x.abbr == this.barco.type_wound_artifacts
                  ).state
                : this.barco.type_wound_artifacts_state != null
                ? this.barco.type_wound_artifacts_state
                : "-",
              this.barco.amount_artifacts != null
                ? this.barco.amount_artifacts
                : "-",
              concepto.items_select_artifact_material.find(
                (x) => x.abbr == this.barco.artifacts_material
              ) != undefined
                ? concepto.items_select_artifact_material.find(
                    (x) => x.abbr == this.barco.artifacts_material
                  ).state
                : this.barco.artifacts_material_state != null
                ? this.barco.artifacts_material_state
                : "-",
            ],
          ],
          theme: "grid",
        });
      }

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Tipo de FAD utilizados",
            "Cantidad de FAD utilizados",
            "Componentes del FAD",
          ],
        ],
        body: [
          [
            this.barco.fad_amount != null ? this.barco.fad_amount : "-",
            concepto.items_select_type_fad.find(
              (x) => x.abbr == this.barco.type_fad
            ) != undefined
              ? concepto.items_select_type_fad.find(
                  (x) => x.abbr == this.barco.type_fad
                ).state
              : this.barco.type_fad_state != null
              ? this.barco.type_fad_state
              : "-",
            this.barco.fad_components != null ? this.barco.fad_components : "-",
          ],
        ],
        theme: "grid",
      });
      doc.addPage();

      doc.setDrawColor(0, 0, 0);

      doc.addImage(this.escudo, "PNG", 15, 18, 20, 20);

      doc.line(15, 15, 195, 15); // horizontal line

      doc.setFontSize(12).text("Gobernación", 40, 23);
      doc.setFontSize(12).text("Departamento de San Andrés,", 40, 27);
      doc.setFontSize(12).text("Providencia y Santa Catalina", 40, 31);
      doc.setFontSize(12).text("Secretaría de Agricultura y Pesca", 40, 35);
      doc.line(15, 40, 195, 40); // horizontal line

      doc.setFontSize(12).text("Fecha de", 120, 23);
      doc.setFontSize(12).text("Aprobación:", 120, 27);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(new Date().toISOString().substr(0, 10), 120, 31);

      doc.setFontSize(12).text("Código", 150, 23);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);

      doc.rect(10, 52, 190, 70);

      doc.setFontSize(12).text("Observaciones:", 15, 48);

      doc.fromHTML(
        this.editedItem.concepto_recomendaciones || '',
        15,
        53,
        {
          width: "180",
          lineHeight: 2,
        },
        function (dispose) {
          dispose;
        }
      );

      doc.addImage(this.firma_fun, "PNG", 25, 130, 50, 50);

      doc.addImage(this.firma_rep, "PNG", 130, 130, 50, 50);

      doc.line(15, 185, 80, 185);

      doc.line(130, 185, 195, 185);

      doc
        .setFontSize(12)
        .text("Firma Representante", 170, 190, null, null, "right");
      doc
        .setFontSize(12)
        .text(
          (this.editedItem.nombre_representante || "").toUpperCase(),
          130,
          197,
          { maxWidth: 180, align: "justify" }
        );
      doc
        .setFontSize(12)
        .text(
          "Cargo :   " +
            (this.editedItem.cargo_representante || "").toUpperCase() +
            "",
          130,
          207,
          { maxWidth: 180, align: "justify" }
        );
      doc
        .setFontSize(12)
        .text(
          (
            this.editedItem.tipo_identificacion_representante || ""
          ).toUpperCase() +
            ": " +
            (
              this.editedItem.numero_identificacion_representante || ""
            ).toUpperCase(),
          130,
          202,
          { maxWidth: 180, align: "justify" }
        );
      doc
        .setFontSize(12)
        .text("Firma Funcionario", 15, 190, null, null, "left");

      doc
        .setFontSize(12)
        .text(
          (this.funcionario.firstname || "").toUpperCase() +
            " " +
            (this.funcionario.lastname || "").toUpperCase() +
            "",
          15,
          195,
          { maxWidth: 180, align: "justify" }
        );

      doc
        .setFontSize(12)
        .text(
          "Cargo :   " + (this.funcionario.cargo || "").toUpperCase() + "",
          15,
          200,
          { maxWidth: 180, align: "justify" }
        );
      let pdfName = "Certificado - " + this.editedItem.consecutive + ".pdf";

      var pageCount = doc.internal.getNumberOfPages();
      var i = 0;
      for (i; i < pageCount; i++) {
        doc.setPage(i);
        doc.text(
          10,
          10,
          "Pag " +
            doc.internal.getCurrentPageInfo().pageNumber +
            " de " +
            pageCount
        );
      }

      doc.save(pdfName);
    },
    CTAgeneratePDF() {
      let doc = new jsPDF();
      doc.addImage(this.escudo, "PNG", 15, 18, 20, 20);

      doc.line(15, 15, 195, 15); // horizontal line

      doc.setFontSize(12).text("Gobernación", 40, 23);
      doc.setFontSize(12).text("Departamento de San Andrés,", 40, 27);
      doc.setFontSize(12).text("Providencia y Santa Catalina", 40, 31);
      doc.setFontSize(12).text("Secretaría de Agricultura y Pesca", 40, 35);
      doc.line(15, 40, 195, 40); // horizontal line
      doc
        .setFontSize(14)
        .text(
          `FICHA Y CONCEPTO TÉCNICO PERMISO DE PESCA COMERCIAL ${
            this.editedItem.type == "ART" ? "ARTESANAL" : "INDUSTRIAL"
          }`,
          15,
          53
        );

      doc.setFontSize(12).text("Código", 150, 23);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);

      doc.autoTable({
        startY: 60,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Tipo de Pesca", "Lugar de Expedición", "Fecha de Expedición"]],
        body: [
          [
            this.editedItem.type == "ART" ? "Artesanal" : "Industrial",
            this.lugar.nombre,
            this.editedItem.expedition_date,
          ],
        ],
        theme: "grid",
      });

      doc
        .setFontSize(12)
        .text("Datos de la Solictud", 15, doc.previousAutoTable.finalY + 10);

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 15,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          ["Radicado", "Fecha de Radicado", "Fecha de Asignación Solicitud"],
        ],
        body: [
          [
            this.editedItem.filed != null ? this.editedItem.filed : "-",
            this.editedItem.date_filing != null
              ? this.editedItem.date_filing
              : "-",
            this.editedItem.date_application_assignment != null
              ? this.editedItem.date_application_assignment
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Tipo de Solicitud"]],
        body: [[this.tipo_solicitud?.nombre]],
        theme: "grid",
      });
      doc
        .setFontSize(12)
        .text(
          "Verificación de Documentos",
          15,
          doc.previousAutoTable.finalY + 10
        );

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 15,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Carta de solicitud",
            "Cámara de Comercio",
            "Copia del Documento de Identificación",
          ],
        ],
        body: [
          [
            this.editedItem.request_letter != null
              ? this.editedItem.request_letter
              : "-",
            this.editedItem.chamber_of_commerce != null
              ? this.editedItem.chamber_of_commerce
              : "-",
            this.editedItem.identification_copy_doc != null
              ? this.editedItem.identification_copy_doc
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Copia de la OCCRE",
            "Plan de Actividades",
            "Fotocopia Tarjeta o Matrícula Profesional",
            "Certificacion empresa que procesara las capturas",
          ],
        ],
        body: [
          [
            this.editedItem.ocre_copy != null
              ? this.editedItem.ocre_copy
              : "N/A",
            this.editedItem.activities_plan != null
              ? this.editedItem.activities_plan
              : "-",
            this.editedItem.photocopy_professional_card != null
              ? this.editedItem.photocopy_professional_card
              : "-",
            this.editedItem.certificado_captura != null
              ? this.editedItem.certificado_captura
              : "N/A",
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Copia de Matrícula de M/N",
            "Contrato de Afiliación de la M/N",
            "Infome anual de actividades",
            "Visita Técnica de M/N",
          ],
        ],
        body: [
          [
            this.editedItem.motorcycle_enrollment_copy != null
              ? this.editedItem.motorcycle_enrollment_copy
              : "-",
            this.editedItem.motorcycle_affiliation_cont != null
              ? this.editedItem.motorcycle_affiliation_cont
              : "N/A",
            this.editedItem.annual_activity_report != null
              ? this.editedItem.annual_activity_report
              : "-",
            this.editedItem.motorcycle_technical_visit != null
              ? this.editedItem.motorcycle_technical_visit
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc
        .setFontSize(10)
        .text(
          "NA: No Aplica\nObservaciones: Se verifico en la documentación entregada para la solicitud la presentación de los documentos exigidos por normatividad vigente y se reviso la información concerniente a evaluar la viabilidad de la solicitud, de acuerdo a los siguientes datos:",
          15,
          doc.previousAutoTable.finalY + 5,
          { maxWidth: 180, align: "justify" }
        );

      doc
        .setFontSize(12)
        .text("Datos del Permisionario", 15, doc.previousAutoTable.finalY + 20);

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 25,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Nombre",
            "NIT",
            "Representante Legal",
            "Doc (CC o CE)",
            "Numero de Identificación",
          ],
        ],
        body: [
          [
            this.company.name != null ? this.company.name : "-",
            this.company.nit != null ? this.company.nit : "-",
            this.company.legal_representative != null
              ? this.company.legal_representative
              : "-",
            this.company.id_type != null ? this.company.id_type : "-",
            this.company.identification_number != null
              ? this.company.identification_number
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc
        .setFontSize(12)
        .text("Datos del Domicilio", 15, doc.previousAutoTable.finalY + 10);

      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 15,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Dirección", "Teléfono", "E-mail"]],
        body: [[this.company.address, this.company.phone, this.company.email]],
        theme: "grid",
      });
      doc.addPage();

      doc.addImage(this.escudo, "PNG", 15, 18, 20, 20);

      doc.line(15, 15, 195, 15); // horizontal line

      doc.setFontSize(12).text("Gobernación", 40, 23);
      doc.setFontSize(12).text("Departamento de San Andrés,", 40, 27);
      doc.setFontSize(12).text("Providencia y Santa Catalina", 40, 31);
      doc.setFontSize(12).text("Secretaría de Agricultura y Pesca", 40, 35);
      doc.line(15, 40, 195, 40); // horizontal line

      doc.setFontSize(12).text("Código", 150, 23);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);

      doc
        .setFontSize(10)
        .text(
          "Información del Permiso de Pesca (en caso de prórroga o permiso nuevo que venció por términos)",
          15,
          50,
          { maxWidth: 180, align: "justify" }
        );

        let startY = 57;


        let body = [];
        let head = [];

        if(this.first_permission != null)
        {
          head = [
            [
              "No. Resolución",
              "Fecha inicio autorización",
              "Fecha fin autorización",
            ],
          ];
          body = [
            [
              this.first_permission.resolution_number,
              this.first_permission.authorization_init_date,
              this.first_permission.authorization_end_date,
            ]
          ];

          doc.setFontSize(10).text("Primer permiso Otorgado", 15, 57, {
            maxWidth: 180,
            align: "justify",
          });

          doc.autoTable({
            startY: 60,
            headStyles: {
              fillColor: [128, 128, 128],
            },
            head: head,
            body: body,
            theme: "grid",
          });
          startY = doc.previousAutoTable.finalY + 7;
        }

      if (
        this.editedItem.request_type.codigo == requestTypes.PRORROGA ||
        this.editedItem.request_type.codigo == requestTypes.REACTIVACION_PERMISO
      ) {
        doc
          .setFontSize(10)
          .text(
            "Información del Permiso de Pesca (Prorroga o Reactivación)",
            15,
            doc.previousAutoTable.finalY + 5,
            { maxWidth: 180, align: "justify" }
          );
      }
      doc.autoTable({
        startY: startY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          ["No. Resolución", "Fecha", "Fecha Notificación", "Expedido Por"],
        ],
        body: [
          [
            this.permiso.authorization_number,
            this.permiso.date_of_issue,
            this.permiso.date_of_notification,
            this.permiso.expedido_por != null
              ? this.permiso.expedido_por.nombre
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Titular de la Autorización",
            "Fecha inicio autorización",
            "Fecha fin autorización",
            "Vigencia (años)",
          ],
        ],
        body: [
          [
            this.permiso.titular_autorizacion != null
              ? this.permiso.titular_autorizacion.nombre
              : "-",
            this.permiso.effective_activation_date,
            this.permiso.effective_inactivation_date,
            this.permiso.duration_of_permit_granted == "UNO"
              ? 1
              : this.permiso.duration_of_permit_granted == "DOS"
              ? 2
              : this.permiso.duration_of_permit_granted == "TRES"
              ? 3
              : this.permiso.duration_of_permit_granted == "CUATRO"
              ? 4
              : this.permiso.duration_of_permit_granted == "CINCO"
              ? 5
              : "-",
          ],
        ],
        theme: "grid",
      });

      let port_l = "";
      if (this.type == "art") {
        port_l = concepto.items_select_authorized_landing_port.find(
          (x) => x.codigo == this.permiso.landing_zone
        ).nombre;
      } else {
        port_l = concepto.items_select_authorized_landing_port_ind.find(
          (x) => x.codigo == this.permiso.landing_zone
        ).nombre;
      }

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Zona autorizada",
            "Puerto autorizado",
            "Tipo de pesquera",
            "Especies Autorizadas",
          ],
        ],
        body: [
          [
            this.permiso.zona_autorizada != null
              ? this.permiso.zona_autorizada.nombre
              : "-",

            port_l != undefined ? port_l : this.permiso.landing_zone,
            this.permiso.type_fishery.map((x) => x.pivot.description),
            this.permiso.especies_autorizadas.map((x) => x.nombre),
          ],
        ],
        theme: "grid",
      });

      const arts_auth = this.permiso.authorized_arts.split(",");

      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Artes de Pesca", "Artes Autorizadas"]],
        body: [
          [
            this.permiso.fishing_arts.map((x) => x.nombre),
            arts_auth.map((x) => {
              let arte = "";
              arte += concepto.items_select_authorized_arts.find(
                (b) => b.codigo == x
              ).nombre;
              return arte;
            }),
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Valor de tasa Anual"]],
        body: [
          [
            this.editedItem.valor_tasa_anual != null
              ? this.editedItem.valor_tasa_anual
              : "-",
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Valor de tasa Anual en Letras"]],
        body: [
          [
            this.editedItem.valor_tasa_anual != null
              ? this.valor_tasa(this.editedItem.valor_tasa_anual)
              : "-",
          ],
        ],
        theme: "grid",
      });

      if (this.tpye == "ind") {
        doc.autoTable({
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [["Tipo de Autorización"]],

          body: [
            [
              this.editedItem.type_of_authorization != null
                ? this.editedItem.type_of_authorization == "UNO"
                  ? "1. Pesca Comercial Industrial"
                  : "2. Permiso Integrado de Pesca"
                : "-",
            ],
          ],
          theme: "grid",
        });
      }

      doc.addPage();

      doc.setFontSize(12).text("Motonave (s) Relacionadas", 15, 17);

      //  var i =0;
      this.barcos.forEach((boat) => {
        // var startY = 0;
        // if (i == 0) {
        //   startY = 25;
        // }
        const estado =
          boat.estado == 0
            ? "Caracteristicas de Motonave a Vincular"
            : boat.estado == 1
            ? "Caracteristicas de Motonave Vinculada"
            : boat.estado == 2
            ? "Caracteristicas de Motonave a Desvincular"
            : boat.estado == 3
            ? "Caracteristicas de Motonave Desvinculada"
            : "-";
        doc.setFontSize(12).text(`${estado}`, 15, 25);

        doc.autoTable({
          startY: 30,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Nombre",
              "Matrícula",
              "Lugar de Expedicion",
              "Matricula Expedida por",
              "Expedicion de Matricula",
              "Vencimiento de la Matricula",
            ],
          ],
          body: [
            [
              boat.boat_name != null ? boat.boat_name : "-",
              boat.registration_number != null ? boat.registration_number : "-",
              boat.expedition_place != null ? boat.expedition_place : "-",
              boat.expedition_by != null ? boat.expedition_by : "-",
              boat.expedition_date != null ? boat.expedition_date : "-",
              boat.expiration_date != null ? boat.expiration_date : "-",
            ],
          ],
          theme: "grid",
        });
        doc.autoTable({
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Estado actual del pabellón",
              "Puerto de Registro",
              "Tipo de barco",
            ],
          ],
          body: [
            [
              concepto.items_select_current_state_pavilion.find(
                (x) => x.abbr == boat.current_state_pavilion
              ) != undefined
                ? concepto.items_select_current_state_pavilion.find(
                    (x) => x.abbr == boat.current_state_pavilion
                  ).state
                : "-",

              concepto.items_select_registration_port.find(
                (x) => x.abbr == boat.registration_port
              ) != undefined
                ? concepto.items_select_registration_port.find(
                    (x) => x.abbr == boat.registration_port
                  ).state
                : boat.registration_port_state != null
                ? boat.registration_port_state
                : boat.registration_port,
              boat.boat_type_state != null
                ? boat.boat_type_state
                : boat.boat_type != null
                ? boat.boat_type.nombre
                : "-",
            ],
          ],
          theme: "grid",
        });

        doc.autoTable({
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "ESLORA - Longitud total (LOA)(m)",
              "Calado/ Proyecto(m)",
              "Manga / extrema amplitud (m)",
              "Tonelaje de Registro Neto (TRN)",
              "Tonelaje de Registro Bruto (TRB)",
            ],
          ],
          body: [
            [
              boat.length_overall != null ? boat.length_overall : "-",
              boat.calado != null ? boat.calado : "-",
              boat.beam_extreme_breadth != null
                ? boat.beam_extreme_breadth
                : "-",
              boat.net_registered_tonnage != null
                ? boat.net_registered_tonnage
                : "-",
              boat.gross_registered_tonnage != null
                ? boat.gross_registered_tonnage
                : "-",
            ],
          ],
          theme: "grid",
        });
        doc.autoTable({
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Tipo de Propulsión",
              "Potencia del motor principal/s",
              "Unidad de Potencia",
              "Alto de la bodega de pescado (m)",
            ],
          ],
          body: [
            [
              concepto.items_select_propulsion_type.find(
                (x) => x.abbr == boat.type_of_propulsion
              ) != undefined
                ? concepto.items_select_propulsion_type.find(
                    (x) => x.abbr == boat.type_of_propulsion
                  ).state
                : boat.type_of_propulsion_state != null
                ? boat.type_of_propulsion_state
                : "No Aplica",
              boat.main_engines_power != null ? boat.main_engines_power : "-",
              concepto.items_select_power_unit.find(
                (x) => x.abbr == boat.power_unit
              ) != undefined
                ? concepto.items_select_power_unit.find(
                    (x) => x.abbr == boat.power_unit
                  ).state
                : boat.power_unit_state != null
                ? boat.power_unit_state
                : "-",
              boat.fish_hold_high != null ? boat.fish_hold_high : "-",
            ],
          ],
          theme: "grid",
        });
        doc.autoTable({
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Largo de la bodega de pescado (m)",
              "Ancho de la bodega de pescado (m)",
              "Capacidad de la bodega de pescado (m³)",
              "Tipo de conservación del pescado",
            ],
          ],
          body: [
            [
              boat.fish_hold_long != null ? boat.fish_hold_long : "-",
              boat.fish_hold_width != null ? boat.fish_hold_width : "-",
              boat.fish_hold_capacity != null ? boat.fish_hold_capacity : "-",
              boat.fish_hold_type_state != null
                ? boat.fish_hold_type_state
                : boat.fish_hold_type != null
                ? boat.fish_hold_type.nombre
                : "-",
            ],
          ],
          theme: "grid",
        });
        doc.autoTable({
          startY: doc.previousAutoTable.finalY + 5,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [["Artes de pesca"]],
          body: [
            [
              boat.fishing_arts.length > 0
                ? boat.fishing_arts.map((x) => x.pivot.description)
                : "No se registraron artes de pesca",
            ],
          ],
          theme: "grid",
        });
        if (boat.fishing_arts.find((x) => x.codigo == "UNO") != undefined) {
          doc
            .setFontSize(12)
            .text(
              `${boat.fishing_arts.find((x) => x.codigo == "UNO").nombre}`,
              15,
              doc.previousAutoTable.finalY + 5
            );

          doc.autoTable({
            startY: doc.previousAutoTable.finalY + 7,
            headStyles: {
              fillColor: [128, 128, 128],
            },
            head: [
              [
                "Denominación del arte de pesca tipo Sedal ó de anzuelo",
                "Tipo de anzuelo",
                "Tamaño del anzuelo",
                "Cantidad de anzuelos",
                "Longitud de la línea madre",
                "Material de la línea madre",
                "Material de las bajantes",
              ],
            ],
            body: [
              [
                concepto.items_select_type_line.find(
                  (x) => x.abbr == boat.fishing_line
                ) != undefined
                  ? concepto.items_select_type_line.find(
                      (x) => x.abbr == boat.fishing_line
                    ).state
                  : boat.fishing_line_state != null
                  ? boat.fishing_line_state
                  : "-",
                boat.hook_type != null
                  ? concepto.items_select_type_hook.find(
                      (x) => x.abbr == boat.hook_type
                    ).state
                  : boat.hook_type_state != null
                  ? boat.hook_type_state
                  : "-",
                boat.hook_size != null ? boat.hook_size : "-",
                boat.hooks_number != null ? boat.hooks_number : "-",
                boat.mother_line_length != null ? boat.mother_line_length : "-",
                concepto.items_select_mother_line_material.find(
                  (x) => x.abbr == boat.mother_line_material
                ) != undefined
                  ? concepto.items_select_mother_line_material.find(
                      (x) => x.abbr == boat.mother_line_material
                    ).state
                  : boat.mother_line_material_state != null
                  ? boat.mother_line_material_state
                  : "-",

                concepto.items_select_downspout_material.find(
                  (x) => x.abbr == boat.downpipe_material
                ) != undefined
                  ? concepto.items_select_downspout_material.find(
                      (x) => x.abbr == boat.downpipe_material
                    ).state
                  : boat.downpipe_material_state != null
                  ? boat.downpipe_material_state
                  : "-",
              ],
            ],
            theme: "grid",
          });
        }
        if (boat.fishing_arts.find((x) => x.codigo == "DOS") != undefined) {
          doc
            .setFontSize(12)
            .text(
              `${boat.fishing_arts.find((x) => x.codigo == "DOS").nombre}`,
              15,
              doc.previousAutoTable.finalY + 5
            );

          doc.autoTable({
            startY: doc.previousAutoTable.finalY + 7,
            headStyles: {
              fillColor: [128, 128, 128],
            },
            head: [
              [
                "Denominación del arte de pesca tipo trampas o nasas",
                "Cantidad de trampas o nasas",
                "Material principal de la trampa o nasa",
              ],
            ],
            body: [
              [
                concepto.items_select_denomination_art_fishing.find(
                  (x) => x.abbr == boat.art_type_traps
                ) != undefined
                  ? concepto.items_select_denomination_art_fishing.find(
                      (x) => x.abbr == boat.art_type_traps
                    ).state
                  : boat.art_type_traps_state != null
                  ? boat.art_type_traps_state
                  : "-",
                boat.type_traps_number != null ? boat.type_traps_number : "-",
                concepto.items_select_main_trap_material.find(
                  (x) => x.abbr == boat.type_traps_material
                ) != undefined
                  ? concepto.items_select_main_trap_material.find(
                      (x) => x.abbr == boat.type_traps_material
                    ).state
                  : boat.type_traps_material_state != null
                  ? boat.type_traps_material_state
                  : "-",
              ],
            ],
            theme: "grid",
          });
        }

        if (boat.fishing_arts.find((x) => x.codigo == "TRES") != undefined) {
          doc
            .setFontSize(12)
            .text(
              `${boat.fishing_arts.find((x) => x.codigo == "TRES").nombre}`,
              15,
              doc.previousAutoTable.finalY + 5
            );

          doc.autoTable({
            startY: doc.previousAutoTable.finalY + 7,
            headStyles: {
              fillColor: [128, 128, 128],
            },
            head: [
              [
                "Tipo de artefactos de herir o aferrar",
                "Cantidad de artefactos",
                "Material del artefacto",
              ],
            ],
            body: [
              [
                concepto.items_select_type_artifact.find(
                  (x) => x.abbr == boat.type_wound_artifacts
                ) != undefined
                  ? concepto.items_select_type_artifact.find(
                      (x) => x.abbr == boat.type_wound_artifacts
                    ).state
                  : boat.type_wound_artifacts_state != null
                  ? boat.type_wound_artifacts_state
                  : "-",
                boat.amount_artifacts != null ? boat.amount_artifacts : "-",

                concepto.items_select_artifact_material.find(
                  (x) => x.abbr == boat.artifacts_material
                ) != undefined
                  ? concepto.items_select_artifact_material.find(
                      (x) => x.abbr == boat.artifacts_material
                    ).state
                  : boat.artifacts_material_state != null
                  ? boat.artifacts_material_state
                  : "-",
              ],
            ],
            theme: "grid",
          });
        }

        doc.autoTable({
          startY: doc.previousAutoTable.finalY,
          headStyles: {
            fillColor: [128, 128, 128],
          },
          head: [
            [
              "Tipo de FAD utilizados",
              "Cantidad de FAD utilizados",
              "Componentes del FAD",
            ],
          ],
          body: [
            [
              boat.fad_amount != null ? boat.fad_amount : "-",
              concepto.items_select_type_fad.find(
                (x) => x.abbr == boat.type_fad
              ) != undefined
                ? concepto.items_select_type_fad.find(
                    (x) => x.abbr == boat.type_fad
                  ).state
                : boat.type_fad_state != null
                ? boat.type_fad_state
                : "-",
              boat.fad_components != null ? boat.fad_components : "-",
            ],
          ],
          theme: "grid",
        });

        //  const pageHeight= doc.internal.pageSize.height;
        //
        //
        // if (doc.previousAutoTable.finalY+60>=pageHeight)
        //  {

        doc.addPage();
        //  }
        //  i++;
      });

      doc.setDrawColor(0, 0, 0);

      doc.addImage(this.escudo, "PNG", 15, 18, 20, 20);

      doc.line(15, 15, 195, 15); // horizontal line

      doc.setFontSize(12).text("Gobernación", 40, 23);
      doc.setFontSize(12).text("Departamento de San Andrés,", 40, 27);
      doc.setFontSize(12).text("Providencia y Santa Catalina", 40, 31);
      doc.setFontSize(12).text("Secretaría de Agricultura y Pesca", 40, 35);
      doc.line(15, 40, 195, 40); // horizontal line

      doc.setFontSize(12).text("Fecha de", 120, 23);
      doc.setFontSize(12).text("Aprobación:", 120, 27);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(new Date().toISOString().substr(0, 10), 120, 31);

      doc.setFontSize(12).text("Código", 150, 23);
      doc
        .setFontStyle("italic")
        .setFontSize(12)
        .text(this.editedItem.consecutive, 150, 27);

      doc.rect(10, 52, 190, 150);
      doc.setFontSize(12).text("Concepto y Recomendaciones :", 15, 48);

      doc.fromHTML(
        this.editedItem.concepto_recomendaciones,
        15,
        53,
        {
          // y coord

          width: "180",
          lineHeight: 2, // max width of content on PDF
          // max width of content on PDF
        },
        function (dispose) {
          // dispose: object with X, Y of the last line add to the PDF
          // this allow the insertion of new lines after html
          dispose;
        }
      );

      if (this.editedItem.request_type != "SEIS") {
        doc
          .setFontSize(12)
          .text(
            " Al revisar la información presentada por el Permisionario, se puede determinar que " +
              this.company.name +
              ` cumple con los requisitos para otorgársele PERMISO DE PESCA COMERCIAL ${
                this.editedItem.type == "IND" ? "INDUSTRIAL" : "ARTESANAL"
              } para la captura de ` +
              this.permiso.type_fishery.map((x) => x.pivot.description) +
              " según las especies señaladas anteriormente.  ",
            15,
            210,
            { maxWidth: 180, align: "justify" }
          );
      }
      doc.addImage(this.firma_fun, "PNG", 110, 220, 50, 50);
      doc
        .setFontSize(12)
        .text(
          "Firma del Servidor Público y/o Contratista: ________________________",
          15,
          270,
          { maxWidth: 180, align: "justify" }
        );
      doc
        .setFontSize(12)
        .text(
          "Nombre del Servidor Público y/o Contratista:  " +
            (this.funcionario.firstname || "").toUpperCase() +
            " " +
            (this.funcionario.lastname || "").toUpperCase() +
            "",
          15,
          280,
          { maxWidth: 180, align: "justify" }
        );
      doc
        .setFontSize(12)
        .text(
          "Cargo del Servidor Público y/o Contratista:   " +
            (this.funcionario.cargo || "").toUpperCase() +
            "",
          15,
          290,
          { maxWidth: 180, align: "justify" }
        );
      let pdfName = "Certificado - " + this.editedItem.consecutive + ".pdf";
      var pageCount = doc.internal.getNumberOfPages();
      var i = 0;
      for (i; i < pageCount; i++) {
        doc.setPage(i);
        doc.text(
          10,
          10,
          "Pag " +
            doc.internal.getCurrentPageInfo().pageNumber +
            " de " +
            pageCount
        );
      }
      doc.save(pdfName);
    },
    CPAgeneratePDF() {
      
      let doc = new jsPDF();
      doc.addImage(this.escudo, "PNG", 15, 18, 20, 20);
      doc.line(15, 15, 195, 15); // horizontal line
      doc.addImage(this.watermark, "PNG", 34, 83, 140, 140, "FAST", 180);

      doc.setFontSize(12).text("Gobernación", 40, 23);
      doc.setFontSize(12).text("Departamento de San Andrés,", 40, 27);
      doc.setFontSize(12).text("Providencia y Santa Catalina", 40, 31);
      doc.setFontSize(12).text("Secretaría de Agricultura y Pesca", 40, 35);
      doc.line(15, 40, 195, 40); // horizontal line
      doc
        .setFontSize(14)
        .text(
          "CERTIFICADO DE PATENTE DE PESCA N° " + this.editedItem.consecutive,
          doc.internal.pageSize.width / 2,
          53,
          null,
          null,
          "center"
        );

      doc.setFontSize(12).text("Fecha de", 120, 23);
      doc.setFontSize(12).text("Aprobación:", 120, 27);
      doc.setFontStyle("italic").setFontSize(12).text("2020-01-29", 120, 31);

      doc.setFontSize(12).text("Código", 150, 23);
      doc.setFontStyle("italic").setFontSize(12).text("FO-MI-DP-15", 150, 27);
      var pageCount = doc.internal.getNumberOfPages();
      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: 60,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          ["Tipo de Permiso", "Lugar de Solicitud", "Fecha de Expedición"],
        ],
        body: [
          [
            this.type == "art" ? "Artesanal" : "Industrial",
            this.lugar.nombre,
            this.barco.date_expedition_patent,
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Vigencia (meses)",
            "Fecha de inicio",
            "Fecha de vencimiento",
            "Valor Derechos Aplicables (pesos)",
          ],
        ],
        body: [
          [
            this.barco.validity_of_patent,
            this.barco.date_expedition_patent,
            this.barco.date_expiration_patent,
            Intl.NumberFormat('es-CO',{style:'currency',currency:'COP'}).format(Number(this.editedItem.valor_tasa_anual)),
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Valor Derechos Aplicables en Letras"]],
        body: [
          [
            this.editedItem.valor_tasa_anual != null
              ? this.valor_tasa(this.editedItem.valor_tasa_anual)
              : "-",
          ],
        ],
        theme: "grid",
      });

      doc
        .setFontSize(8)
        .text(
          "DE CONFORMIDAD CON LAS DISPOCISIONES DE LOS DECRETOS NÚMEROS 2256 DE 1991 Y 1300 DEL 2003 Y DEMÁS NORMAS \n VIGENTES, SE CONCEDE CERTIFICADO DE PATENTE DE PESCA MARÍTIMA A LA EMBARCACIÓN AL SERVICIO DE:",
          15,
          doc.previousAutoTable.finalY + 6
        );

      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: doc.previousAutoTable.finalY + 13,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Permisionario y/o Titular del Permiso", "Tipo de Pesquería"]],
        body: [
          [
            this.dataItem.requestable_type === this.requestables.FISHERMEN ? `${this.dataItem.requestable.full_name}` : this.company.name,
            this.barco.type_fishery.map((x) => x.pivot.description),
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Artes de Pesca", "Área de Operaciones"]],
        body: [
          [
            this.barco.fishing_arts.map((x) => x.pivot.description),
            this.barco.fishing_zone != null ? this.barco.fishing_zone : "-",
          ],
        ],
        theme: "grid",
      });

      doc
        .setFontSize(12)
        .text(
          "CARACTERÍSTICAS",
          doc.internal.pageSize.width / 2,
          doc.previousAutoTable.finalY + 10,
          null,
          null,
          "center"
        );

      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: doc.previousAutoTable.finalY + 15,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "Nombre de la Embarcación",
            "Bandera",
            "Eslora (m)",
            "Calado/Proyecto(m)",
            "Manga (m)",
          ],
        ],
        body: [
          [
            this.barco.boat_name,
            concepto.items_select_current_state_pavilion.find(
              (x) => x.abbr == this.barco.current_state_pavilion
            ) != undefined
              ? concepto.items_select_current_state_pavilion.find(
                  (x) => x.abbr == this.barco.current_state_pavilion
                ).state
              : "-",

            this.barco.length_overall,
            this.barco.calado,
            this.barco.beam_extreme_breadth,
          ],
        ],
        theme: "grid",
      });
      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [["Tipo de barco", "Registro Bruto (TRB)", "Regitro Neto (TRN)"]],
        body: [
          [
            this.barco.boat_type_state != null
              ? this.barco.boat_type_state
              : this.barco.boat_type != null
              ? this.barco.boat_type.nombre
              : "-",
            this.barco.gross_registered_tonnage,
            this.barco.net_registered_tonnage,
          ],
        ],
        theme: "grid",
      });

      doc.autoTable({
        tableLineWidth: 0.3,
        tableLineColor: 10,
        bodyStyles: {
          lineColor: 10,
          lineWidth: 0.3,
          fillColor: false,
        },
        startY: doc.previousAutoTable.finalY,
        headStyles: {
          fillColor: [128, 128, 128],
        },
        head: [
          [
            "No. de Tripulantes",
            "No. de Pescadores",
            "Potencia del Motor",
            "No. de Matrícula",
            "Expedida Por",
          ],
        ],
        body: [
          [
            this.barco.number_of_crew,
            this.barco.number_of_fishermens,
            this.barco.main_engines_power,
            this.barco.registration_number,
            this.barco.expedition_by,
          ],
        ],
        theme: "grid",
      });

      doc
        .setFontSize(8)
        .text(
          "NOMBRE DEL GERENTE / OPERADOR / ARMADOR",
          15,
          doc.previousAutoTable.finalY + 55
        );

      doc.setDrawColor(0, 0, 0);

      doc.line(
        15,
        doc.previousAutoTable.finalY + 76,
        95,
        doc.previousAutoTable.finalY + 76
      );

      doc
        .setFontSize(12)
        .text(
          "" + this.barco.manager_operator,
          15,
          doc.previousAutoTable.finalY + 80
        );
      const legend = "SECRETARIO DE AGRICULTURA Y PESCA";

      doc.setFontSize(8).text(legend, 120, doc.previousAutoTable.finalY + 55);

      doc
        .setFontSize(12)
        .text(
          `${this.editedItem.nombre_secretario || ''}`,
          120,
          doc.previousAutoTable.finalY + 80
        );

      doc.line(
        120,
        doc.previousAutoTable.finalY + 76,
        200,
        doc.previousAutoTable.finalY + 76
      );

      doc.line(
        15,
        doc.previousAutoTable.finalY + 50,
        195,
        doc.previousAutoTable.finalY + 50
      );

      doc
        .setFontSize(8)
        .text("Observaciones:", 15, doc.previousAutoTable.finalY + 10);

      doc.setFontSize(8).fromHTML(
        `<small>${this.editedItem.concepto_recomendaciones || ''}</small>`,
        15,
        doc.previousAutoTable.finalY + 10,
        {
          // y coord

          width: "180", // max width of content on PDF
        },
        function (dispose) {
          // dispose: object with X, Y of the last line add to the PDF
          // this allow the insertion of new lines after html
          dispose;
        }
      );

      let pdfName = "Certificado - " + this.editedItem.consecutive + ".pdf";

      var i = 0;
      for (i; i < pageCount; i++) {
        doc.setPage(i);
        doc.text(
          10,
          10,
          "Pag " +
            doc.internal.getCurrentPageInfo().pageNumber +
            " de " +
            pageCount
        );
      }
      doc.save(pdfName);
    },
  },
};
</script>

<style lang="scss" scoped></style>
